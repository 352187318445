<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <top-info-action
        :showRightItem="false"
        subtitle="Busca, filtra y visualiza kardex por paciente"
        title="Kardex"
    />
    <title-info-card class="mt-5" icon="mdi-google-spreadsheet" textTitle="Kardex">
      <template #contentCard>
        <v-row class="mt-3">
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <div>
              <label class="input-label">Paciente<span
                  class="required-field-form">*</span></label>
              <v-autocomplete
                  :items="patients"
                  return-object
                  :rules="required"
                  :item-text="getName"
                  hide-details
                  outlined
                  color="backofficeInput"
                  placeholder="Selecciona un producto"
                  clearable
                  @change="setPatient"
              />
            </div>
          </v-col>
          <v-col class="mt-2" v-if="currentElement && currentElement.uuid" cols="12" xs="12" sm="12" md="12" lg="12"
                 xl="12">
            <div>
              <span>Fecha de nacimiento: {{ currentElement.birthday }}</span>
              <span class="ml-3">{{ getBirthday }}</span>
            </div>
          </v-col>
          <v-col v-if="currentElement && currentElement.uuid" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <inventory-patient-diagnosis-resume :patient-id="currentElement.uuid"></inventory-patient-diagnosis-resume>
          </v-col>
          <v-col v-if="currentElement && currentElement.uuid" cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <inventory-patient-alergic-resume :patient-id="currentElement.uuid"></inventory-patient-alergic-resume>
          </v-col>
          <v-col class="mt-5" v-if="currentElement && currentElement.uuid" cols="12" xs="12" sm="12" md="12" lg="12"
                 xl="12">
            <inventory-patient-cardex-resume :patient-id="currentElement.uuid"
                                             :patient-name="getPatientName"></inventory-patient-cardex-resume>
          </v-col>
        </v-row>
      </template>
    </title-info-card>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs"
import TopInfoAction from "../components/TopInfoAction.vue"
import TitleInfoCard from "../components/TitleInfoCard.vue"
import {required} from "../utils/validationRule"
import customStoreService from "../services/customStore"
import InventoryPatientDiagnosisResume from "../components/inventoryPatient/InventoryPatientDiagnosisResume.vue"
import InventoryPatientAlergicResume from "../components/inventoryPatient/InventoryPatientAlergicResume.vue"
import InventoryPatientCardexResume from "../components/inventoryPatient/InventoryPatientCardexResume.vue"

export default {
  name: "kardexSummary",
  components: {
    InventoryPatientCardexResume,
    InventoryPatientAlergicResume,
    InventoryPatientDiagnosisResume,
    TitleInfoCard,
    TopInfoAction,
    Breadcrumbs
  },
  data: () => ({
    required,
    loading: false,
    search: "",
    searchTimeout: null,
    breadcrumbsItems: [
      {
        text: "Inicio",
        disabled: false,
        path: "/dashboard",
      },
      {
        text: "Kardex",
        disabled: true,
        path: "/kardex",
      },
    ],
    patients: [],
    currentElement: {}
  }),

  computed: {
    getPatientName() {
      return `${this.currentElement?.firstName || ''} ${this.currentElement?.lastName || ''}`
    },
    getBirthday() {
      if (!this.currentElement?.birthday) return 'N/A'
      const [day, month, year] = this.currentElement.birthday.split("/")
      const birthDate = new Date(`${year}-${month}-${day}`)

      if (isNaN(birthDate)) {
        throw new Error("Fecha inválida, asegúrate de que esté en formato DD/MM/YYYY");
      }

      const today = new Date()
      let age = today.getFullYear() - birthDate.getFullYear()
      const monthDifference = today.getMonth() - birthDate.getMonth()
      const dayDifference = today.getDate() - birthDate.getDate()

      if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
      }

      return `${age} años`
    }
  },

  async mounted() {
    const res = await customStoreService.getDataByModuleAndParam(
        'patients',
        '',
        0,
        10000,
    )
    this.patients = res?.data
  },
  /*watch: {
    search(newSearch) {
      console.log(newSearch, 'newSearch')
      if (!newSearch) {
        return
      }
      this.onSearch(newSearch);
    },
  }*/
  methods: {
    setPatient(item) {
      console.log(item)
      this.currentElement = null
      setTimeout(() => {
        this.currentElement = item
      }, 100)
    },
    getName(item) {
      return `${item?.firstName} ${item?.lastName}`
    },
    /* async onSearch(search) {
       if (!search) {
         const res = await customStoreService.getDataByModuleAndParam(
             'patients',
             '',
             0,
             10000,
         )
         this.patients = res?.data || []
       }

       clearTimeout(this.searchTimeout)

       this.searchTimeout = setTimeout(async () => {
         this.loading = true;
         try {
           const res = await customStoreService.getDataByModuleAndParam(
               'patients',
               search,
               0,
               10000,
           );
           this.patients = res?.data || []
         } catch (error) {
           console.error('Error al buscar pacientes:', error)
           this.patients = []
         } finally {
           this.loading = false;
         }
       }, 300)
     },*/
  },
};
</script>

<style lang="scss" scoped></style>
