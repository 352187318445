<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="9" md="9" sm="6" xl="9" xs="12">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          ></v-text-field>
        </v-col>
        <v-col v-if="hasAccess('/inventory-orders/download', 'GET')" cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
          <download-btn module="/inventory-orders/download" titleBtn="orden de inventario"
                        fileDownloadName="orden-de-inventario"></download-btn>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :loading="loaded"
          :options.sync="options"
          :mobile-breakpoint="200"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          hide-default-header
          item-key="uuid"
          :footer-props="{
                      'items-per-page-options': [10, 20, 30, 40, 1000]
                     }"
          @click:headers="handleHeaderClick"
      >
        <template v-slot:header="{ props }">
          <thead class="v-data-table-header" style="cursor:pointer;">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value"
                @click="handleHeaderClick(header)">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>

        <template v-slot:item="{ item }">
          <tr
              :class="'tr-title-header'"
          >
            <td>
              <span>{{ item.folio }}</span>
            </td>
            <td>
              <span>{{ formatDate(item.createdAt) }}</span>
            </td>
            <td>
              <span>{{ item.storeOutName || '-' }}</span>
            </td>
            <td>
              <span>{{ item.storeInName || '-' }}</span>
            </td>
            <td>
              <span>{{ getMovementName(item.type) }}</span>
            </td>
            <td>
              <span>{{ item.comments }}</span>
            </td>
            <td>

              <v-tooltip v-if="item.status !== 'null' && item.status" top>
                <template v-slot:activator="{ on }">
                  <div v-on="on" class="border-circle"
                       :style="{'background-color': availableStatus[item.status]?.color}"></div>
                </template>
                <span>{{ availableStatus[item.status]?.title }}</span>
              </v-tooltip>
            </td>
            <td>
              <v-btn @click="changeStatus(item)" :loading="loadingStatus"
                     :style="{'color': availableStatus[item.nextStatus].color}" text v-if="item.nextStatus" small>
                {{ getTranslate('next', item) }}
              </v-btn>
              <v-btn v-if="!item.nextStatus && item.status !== 'null' && item.status" @click="changeStatus(item)" :loading="loadingStatus"
                     :style="{'color': availableStatus[item.status].color}" text  disabled small>
                {{ getTranslate('current', item) }}
              </v-btn>
            </td>
            <td>
              <div class="display-align-center">
                <v-icon
                    v-if="hasAccess('/store-stocks', 'POST')"
                    class="cursor-pointer-action"
                    color="tableActions"
                    @click="editRow(item, 'CrudStoreStock', 'edit')"
                >mdi-magnify
                </v-icon>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-icon
                        v-on="on"
                        v-if="hasAccess('/inventory-orders', 'DELETE') && !thirtyMinutesValidation(item) && (item.status === 'CREATED' || item.status === 'REVIEWED')"
                        class="ml-2 cursor-pointer-action"
                        color="tableActions"
                        @click="deleteRow(item, 'CrudStoreStock', 'delete')"
                    >mdi-cancel
                    </v-icon>
                  </template>
                  <span>Deshacer órden de inventario</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Órden'"
        :message="message"
        :type="type"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import moduleMixin from "@/mixins/moduleMixin";
import DownloadBtn from "../downloadBtn.vue";
import moment from "moment";
import ActionPopUp from "@/components/ActionPopUp.vue";
import customStoreService from "../../services/customStore";


export default {
  name: "storeStockResume",
  components: {ActionPopUp, DownloadBtn},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      module: "inventory-orders",
      availableStatus: {
        'REVIEWED': {title: 'Revisado', color: '#FF8F00'},
        'APPROVED': {title: 'Aprobado', color: '#4CAF50'},
        'ADJUSTMENT': {title: 'Ajuste', color: '#E65100'},
        'CANCELLED': {title: 'Cancelado', color: '#B71C1C'},
        'CREATED': {title: 'Creado', color: '#0D47A1'},
        'INFORMED': {title: 'Informado', color: '#FFD700'},
        'SOLD': {title: 'Vendido', color: '#4CAF50'}
      },
      loadingStatus: false
    };
  },
  methods: {
    formatDate(date) {
      if (!date) return ''
      return moment(date).format('YYYY-MM-DD HH:mm')
    },
    afterProcess() {
      this.headers = [
        {
          text: "Folio",
          value: "folio",
          sortable: true,
        },
        {
          text: "Fecha",
          value: "createdAt",
          sortable: true,
        },
        {
          text: "Salida",
          sortable: true,
          value: "storeOutId",
        },
        {
          text: "Entrada",
          value: "storeOutName",
          sortable: true,
        },
        {
          text: "Tipo",
          sortable: false,
          value: "type",
        },
        {
          text: "Comentario",
          sortable: false,
          value: "comments",
        },
        {
          text: "Estatus",
          sortable: false,
          value: "status",
        },
        {text: "", value: "actions"},
        {text: "", value: "statusv2"},
      ];
    },
    getMovementName(type) {
      switch (type) {
        case 'MOVEMENT_WAREHOUSE':
          return 'Movimiento Almacén'
        case 'IN_WAREHOUSE':
          return 'Entrada Almacén'
        case 'OUT_WAREHOUSE':
          return 'Salida Almacén'
        default:
          return 'Sin Movimiento'
      }
    },
    thirtyMinutesValidation(item) {
      if (!item?.createdAt) return false
      const fechaProporcionada = moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss');
      const fechaActual = moment();
      const diferenciaEnMinutos = fechaActual.diff(fechaProporcionada, 'minutes');
      return (diferenciaEnMinutos >= 30 || item.status === "CANCELLED")
    },
    async changeStatus(item) {
      try {
        this.loadingStatus = true
        await customStoreService.postDataByModuleAndUrl('inventory-orders', `${item.nextStatus}/${item.uuid}`)
        await this.loadData()
      } catch (error) {
        this.type = "error";
        this.dialog = true;
        this.message = `${error?.response?.data?.message || ''} ${error?.response?.data?.details || ''}`
      } finally {
        this.loadingStatus = false
      }
    },
    getTranslate(type, item) {
      let translationKey = ''
      if (type === 'next') {
        translationKey = `status.${type}.${item.nextStatus}`;
      } else {
        translationKey = `status.${type}.${item.status}`;
      }

      return this.$t(translationKey);
    }
  },
};
</script>

<style scoped>
.personal-information-text > .name {
  font-family: "Montserrat-Regular";
  font-size: 17px;
}

.personal-information-text > .subtitle {
  font-family: "Montserrat-Regular";
  font-size: 14px;
}
</style>
