<template>
  <v-row class="mt-4" no-gutters>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <v-form v-model="validForm" @input="inputForm">
        <v-row no-gutters>
          <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <title-info-card icon="mdi-package-variant" textTitle="Información de producto">
              <template #contentCard>
                <v-row class="mt-2">
                  <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                      <label class="input-label" for="name">Nombre<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model="currentElement.name"
                          id="name"
                          :rules="required"
                          @input="verifyNameInput"
                          @blur="verifyName"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un nombre"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="state">Código local<span
                          class="required-field-form"></span></label>
                      <v-text-field
                          v-model="currentElement.code"
                          id="state"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un código local"
                      />
                    </div>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="city">Código de proveedor<span
                          class="required-field-form"></span></label>
                      <v-text-field
                          v-model="currentElement.providerCode"
                          id="city"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un código de proveedor"
                      />
                    </div>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Categoría de producto<span
                          class="required-field-form">*</span></label>
                      <v-select
                          v-model="currentElement.productCategoryId"
                          id="phone"
                          :items="productCategories"
                          :rules="required"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona una categoría de producto"
                      />
                    </div>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">¿ Es un producto perecedero ?<span
                          class="required-field-form"></span></label>
                        <v-checkbox v-model="currentElement.perishableProduct"></v-checkbox>
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
            <title-info-card class="mt-5" text-title="Precios" icon="mdi-currency-usd" v-if="!currentElement.uuid">
              <template #contentCard>
                <v-row class="mt-2">
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <div>
                      <label class="input-label" for="phone">Presentación<span
                          class="required-field-form">*</span></label>
                      <v-select
                          v-model="currentElement.unitId"
                          id="phone"
                          :items="units"
                          :rules="required"
                          item-value="uuid"
                          item-text="name"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Selecciona una presentación"
                      />
                    </div>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                    <div>
                      <label class="input-label" for="street">Cantidad<span
                          class="required-field-form"></span></label>
                      <div class="v-input">
                        <div class="v-input__control">
                          <div class="v-input__slot">
                            <div class="v-text-field__slot">
                              {{currentElement.quantity}}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                    <div>
                      <label class="input-label" for="street">Precio de venta<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model.number="currentElement.priceSell"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un precio de venta"
                      />
                    </div>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                    <div>
                      <label class="input-label" for="street">Precio de compra<span
                          class="required-field-form">*</span></label>
                      <v-text-field
                          v-model.number="currentElement.priceBuy"
                          id="street"
                          :rules="required"
                          hide-details
                          outlined
                          color="backofficeInput"
                          placeholder="Escribe un precio de compra"
                      />
                    </div>
                  </v-col>
                </v-row>
              </template>
            </title-info-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
      <title-info-card class="mt-5" text-title="Precios" icon="mdi-currency-usd" v-if="currentElement.uuid">
        <template #contentCard>
          <v-row>
            <v-col  class="mt-5"  cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <v-data-table
                  :headers="headers"
                  :items="items"
                  :search="search"
                  :mobile-breakpoint="200"
                  sort-by="calories"
                  :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
                  class="elevation-1"
              >
                <template v-slot:top>
                  <div>
                    <v-toolbar
                        flat
                    >

                      <v-spacer></v-spacer>
                      <v-dialog
                          v-model="dialog"
                          max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                              rounded
                              class="btn-active ma-2"
                              v-bind="attrs"
                              :disabled="!hasAccess('/products', 'POST')"
                              v-on="on"
                          >
                            Agregar
                          </v-btn>
                        </template>
                        <v-card >
                          <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                  <div>
                                    <label class="input-label" for="phone">presentaciones<span
                                        class="required-field-form">*</span></label>
                                    <v-select
                                        v-model="editedItem.unitId"
                                        id="phone"
                                        :items="units"
                                        :rules="required"
                                        item-value="uuid"
                                        item-text="name"
                                        hide-details
                                        outlined
                                        color="backofficeInput"
                                        placeholder="Selecciona una presentación"
                                    />
                                  </div>
                                </v-col>
                                <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                  <div>
                                    <label class="input-label" for="street">Cantidad<span
                                        class="required-field-form">*</span></label>
                                    <v-text-field
                                        v-model.number="editedItem.quantity"
                                        id="street"
                                        :rules="required"
                                        hide-details
                                        outlined
                                        color="backofficeInput"
                                        placeholder="Escribe una cantidad"
                                    />
                                  </div>
                                </v-col>
                                <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                  <div>
                                    <label class="input-label" for="street">Precio de compra<span
                                        class="required-field-form"></span></label>
                                    <v-text-field
                                        v-model.number="editedItem.priceBuy"
                                        id="street"
                                        hide-details
                                        outlined
                                        color="backofficeInput"
                                        placeholder="Escribe un precio de compra"
                                    />
                                  </div>
                                </v-col>
                                <v-col  cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                  <div>
                                    <label class="input-label" for="street">Precio de venta<span
                                        class="required-field-form"></span></label>
                                    <v-text-field
                                        v-model.number="editedItem.priceSell"
                                        id="street"
                                        :rules="required"
                                        hide-details
                                        outlined
                                        color="backofficeInput"
                                        placeholder="Escribe un precio de venta"
                                    />
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                            >
                              Cancelar
                            </v-btn>
                            <v-btn
                                @click="saveUnit"
                                :disabled="!disabledDialogForm"
                                color="blue darken-1"
                                text
                            >
                              Guardar
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                    <div class="mx-4 mb-4">
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Buscar"
                          single-line
                          outlined
                          hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </template>
                <template v-slot:item="{item}">
                  <tr>
                    <td>
                      <span>{{ getUnitName(item) }}</span>
                    </td>
                    <td>
                      <span>{{ item.quantity }}</span>
                    </td>
                    <td>
                      <span>{{ formatPrice(item.priceSell) }}</span>
                    </td>
                    <td>
                      <span>{{ formatPrice(item.priceBuy) }}</span>
                    </td>
                    <td>
                      <v-icon
                          v-if="hasAccess('/product-units', 'POST')"
                          small
                          class="mr-2"
                          @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                          v-if="hasAccess('/product-units', 'DELETE')"
                          small
                          @click="deleteProductUnit(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </template>
      </title-info-card>
    </v-col>
    <ActionPopUp
        :dialog="dialogUnit"
        :iteName="'Presentación'"
        :type="type"
        :message="message"
        @cancelAction="dialogUnit = false"
        @closeAction="closeAction"
    ></ActionPopUp>
    <ActionPopUp
        :dialog="dialogEdit"
        :iteName="'Elemento'"
        :type="typeEdit"
        @cancelAction="dialogEdit = false"
        @closeAction="closeActionEdit"
        @deleteAction="deleteAction"
    ></ActionPopUp>
    <ActionPopUp
         makeList
        :dialog="openConfirmation"
        :iteName="'Confirmar'"
        :type="'info'"
        :arrayInfo=coincidencesProduct
        :titleInfo="`Se encontraron coincidencias al agregar el producto ${currentElement?.name}`"
        @cancelAction="openConfirmation = false"
        @closeAction="closeActionInfo"
        @infoAction="confirmInfo"
    ></ActionPopUp>
  </v-row>
</template>

<script>
import TitleInfoCard from "../../components/TitleInfoCard.vue";
import {required} from "../../utils/validationRule";
import customStore from "@/services/customStore";
import ActionPopUp from "@/components/ActionPopUp.vue";
import customStoreService from "@/services/customStore";
import moduleMixin from "../../mixins/moduleMixin";
let typing;
export default {
  name: "ProductsForm",
  components: {ActionPopUp, TitleInfoCard},
  mixins:[moduleMixin],
  props: {
    currentElement: {
      type: Object,
      default: () => {
      }
    },
    productCategories: {
      type: Array,
      default: () => []
    },
    units: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      openConfirmation:false,
      coincidencesProduct:[],
      typeEdit:'delete',
      dialogEdit: false,
      message: '',
      type:'error',
      dialogUnit:false,
      required,
      validForm: false,
      editedIndex: -1,
      dialog: false,
      defaultItem: {
        unitId:'',
        quantity:0,
        priceSell:0,
        priceBuy:0
      },
      editedItem: {
        unitId:'',
        quantity:0,
        priceSell:0,
        priceBuy:0
      },
      search:'',
      items:[],
      headers:[
        {
          text: 'Presentación',
          sortable: true,
          value: 'unitId',
        },
        {
          text: 'Cantidad',
          sortable: true,
          value: 'quantity',
        },
        {
          text: 'Precio de venta',
          sortable: true,
          value: 'priceSell',
        },
        {
          text: 'Precio de compra',
          sortable: true,
          value: 'priceBuy',
        },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      inputTimeout: null,
      checkInterval: null,
    }
  },
  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Nueva presentación' : 'Editar presentación'
    },
    disabledDialogForm () {
      return this.editedItem.unitId && this.editedItem.quantity
    }
  },
  watch:{
    dialog (val) {
      val || this.close()
    },
  },
 async created () {
    if (this.currentElement?.uuid) {
      await this.getProductUnitByProductId()
    }
  },
  methods: {
    inputForm(e) {
      this.$emit("validFormInput", e);
    },
    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    async getProductUnitByProductId() {
      try {
        const newParams = {
          productId: this.currentElement.uuid
        }
        const {data: dataUniProduct} = await customStore.getDataByModuleAndParam(`product-units`, '', 0, 100000, "", "", "", newParams)
        this.items = dataUniProduct
      }catch(errorMessage) {
        this.close()
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogUnit = true
      }
    },
   async saveUnit() {
      const data = this.editedItem
       if (!data?.productId) {
         data.productId = this.currentElement.uuid
       }
       try {
         await customStore.postDataByModule('product-units', data)
         await this.getProductUnitByProductId()
         this.close()
       }catch(errorMessage) {
         this.close()
         this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
         this.dialogUnit = true
       }
    },
    closeAction() {
      this.dialogUnit = false
      this.message = ''
    },
    closeActionEdit() {
      this.dialogEdit = false
      this.selectedRow = "";
    },
    deleteProductUnit(item) {
      this.dialogEdit = true
      this.selectedRow = item;
    },
   async deleteAction() {
      try {
        await customStoreService.deleteByModule('product-units', this.selectedRow);
        await this.getProductUnitByProductId()
        this.closeActionEdit()
      }catch (errorMessage) {
        this.closeActionEdit()
        this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
        this.dialogUnit = true
      }
    },
    editItem(item) {
      this.dialog = true
      this.editedItem = item
    },
    getUnitName(itemTable) {
      if (!this.units?.length) return 'N/A'
      const {name}  = this.units.find((item)=>item.uuid === itemTable.unitId)
      if (name) {
        return name
      }
      return 'N/A'
    },
    formatPrice(cantidad){
      if (!cantidad) return '$0'
      return cantidad.toLocaleString('en-US',{
        style: 'currency',
        currency: "USD"
      })
    },
    closeActionInfo() {
      this.openConfirmation = false
      this.currentElement.name = ''
      this.coincidencesProduct = []
    },
    confirmInfo() {
      this.openConfirmation = false
    },
    async verifyNameInput(evt) {
      this.currentElement.name = evt.toUpperCase()
      if (!this.currentElement?.uuid) return
      clearInterval(typing);
      typing = setInterval(
          async function () {
           await this.verifyNameService()
            clearInterval(typing);
          }.bind(this),
          500
      );
    },
   async verifyNameService() {
     try {
       if (!this.currentElement?.name) return
       console.log(this.currentElement.name)
       const moduleName = `products/coincidences?search=${encodeURIComponent(this.currentElement.name.toUpperCase())}`
       const res = await customStore.getDataByModuleSimple(moduleName)
       if (res?.length) {
         console.log(res.length)
         this.openConfirmation = true
         this.coincidencesProduct = res.map((item)=>item.name)
       }
     }catch(errorMessage) {
       this.message = `${errorMessage?.response?.data?.message || ''} ${errorMessage?.response?.data?.details || ''}`
       this.dialogUnit = true
     }
    },
    async verifyName() {
     if (this.currentElement?.uuid) return
     await this.verifyNameService()
    }
  },
}
</script>
