<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col cols="12" lg="8" md="8" sm="8" xl="8">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          />
        </v-col>
        <!--
        que siempre no por que alan no quiso
               <v-col cols="12" lg="4" md="4" sm="4" xl="4">
                  <v-select
                      v-model="statusFilter"
                      @change="changeFilterStatus"
                      :items="statusList"
                      item-text="name"
                      item-value="id"
                      label="Status"
                      multiple
                      outlined
                  />
                </v-col>-->
        <!--        <v-col  cols="12" lg="3" md="3" sm="6" xl="3" xs="12">
                  <download-btn module="/inventory/download" titleBtn="Inventario" fileDownloadName="Inventario"></download-btn>
                </v-col>-->
      </v-row>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :mobile-breakpoint="200"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          item-key="storeId"
          hide-default-header
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item.storeName }}</span>
            </td>
            <td>
              <span>{{ item.patientName }}</span>
            </td>
            <td>
              <span>{{ item.totalStock }}</span>
            </td>
            <!--            <td>
                          <div class="border-circle" :style="{'background-color': statusInventory[item.status]}"></div>
                        </td>-->
            <td>
              <v-icon @click="editRow(item, 'CrudInventoryPatients', 'edit',null,  item?.patientId)"
                      class="cursor-pointer-action">
                mdi-magnify
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Paciente'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import tableMixin from "../../mixins/tableMixin.js";
import ActionPopUp from "../../components/ActionPopUp.vue";
import moduleMixin from "@/mixins/moduleMixin";
import customStore from "@/services/customStore";
/*import DownloadBtn from "../downloadBtn.vue";*/

export default {
  name: "InventoryPatientResume",
  components: {ActionPopUp},
  mixins: [tableMixin, moduleMixin],
  data() {
    return {
      dialogFixInventory: false,
      patientStockFile: null,
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      module: "patient-stocks/inventory",
      label: "Busca por nombre o estancia",
      statusFilter: [],
      statusList: [{name: 'Crítico', id: 'CRITICAL'}, {name: 'Bueno', id: 'GOOD'}, {name: 'Mínimo', id: 'MINIMAL'}],
      status: ``,
    };
  },
  methods: {
    afterProcess() {
      this.headers = [
        {
          text: "Estancia",
          sortable: true,
          value: "storeName",
        },
        {
          text: "Paciente",
          sortable: true,
          value: "patientName",
        },
        {
          text: "Cantidad",
          sortable: true,
          value: "totalStock",
        },
        {
          text: "Status",
          sortable: true,
          value: "status",
        },
      ];
    },

    changeFilterStatus() {
      let statusFormatted = "";
      this.statusFilter.forEach((status) => {
        statusFormatted = `${statusFormatted}&status=${status}`
      });
      this.status = statusFormatted;
      this.loadData()
    },

    closeUploadPatientStockDialog() {
      this.dialogPatientStock = false
      this.patientStockFile = null
    },
    async uploadPatientStockFile() {
      const formData = new FormData();
      formData.append('file', this.patientStockFile);

      await customStore.uploadPatientStocks(formData);

      this.patientStockFile = null;
      this.dialogPatientStock = false;
      window.location.reload();
    },
  },
};
</script>

<style scoped></style>
