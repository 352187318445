<template>
  <div class="mt-5">
    <div>
      <v-row>
        <v-col class="pb-0" cols="12" lg="8" md="8" sm="8" xl="8">
          <v-text-field
              v-model="search"
              :label="label"
              hide-details
              outlined
              prepend-icon="mdi-magnify"
              @click:append="cleanText"
              :append-icon="search.length > 0 ? 'mdi-close-circle-outline' : null"
              single-line
              @input="typingSearch"
          />
        </v-col>
        <v-col class="pb-0" cols="12" lg="4" md="4" sm="4" xl="4">
          <v-select
              v-model="statusFilter"
              @change="changeFilterStatus"
              :items="statusList"
              item-text="name"
              item-value="id"
              label="Status"
              multiple
              outlined
          />
        </v-col>
        <v-col v-if="hasAccess('/patient-stock-status-summaries/refresh', 'GET')" cols="12" lg="6" md="6" sm="6" xl="6"
               xs="12">
          <v-btn @click="refresh" rounded style="width: 100%" class="btn-active v-btn-primary" color="primary ">
            <v-icon left>mdi-refresh</v-icon>
            Recargar listado
          </v-btn>
        </v-col>
        <v-col v-if="hasAccess('/patient-stock-status-summaries/download', 'GET')" cols="12" lg="6" md="6"
               sm="6" xl="6" xs="12">
          <download-btn :module='`/patient-stock-status-summaries/download/${patientId}`' titleBtn="Descargar"
                        fileDownloadName="Inventario"></download-btn>
        </v-col>
      </v-row>
      <v-data-table
          :headers="headers"
          :items="dataSource"
          :mobile-breakpoint="200"
          :loading="loaded"
          :options.sync="options"
          :search="search"
          :server-items-length="totalItems"
          class="mt-5"
          item-key="storeId"
          hide-default-header
          :footer-props="{
    'items-per-page-options': [10, 20, 30, 40, 1000]
  }"
      >

        <template v-slot:header="{ props }">
          <thead class="v-data-table-header">
          <tr>
            <th class="text-start" v-for="header in props.headers" :key="header.value">
                    <span>
                        {{ header.text }}
                    </span>
            </th>
          </tr>
          </thead>
        </template>
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
              :style="item.supply ? 'background: #ededed !important' : '' "
              :class="
              isExpanded ? 'expanded-row tr-title-header' : 'tr-title-header'
            "
          >
            <td>
              <span>{{ item?.productCode }}</span>
            </td>
            <td>
              <span>{{ item?.productName }}</span>
            </td>
            <td>
              <span>{{ item?.productCategory }}</span>
            </td>
            <td>
              <span>{{ item.predictionMonth1 }}</span>
            </td>
            <td>
              <span>{{ item.predictionMonth2 }}</span>
            </td>
            <td>
              <span>{{ item.predictionMonth3 }}</span>
            </td>
            <td>
              <span>{{ item.totalStock }}</span>
            </td>
            <td>
              <div class="border-circle" :style="{'background-color': statusInventory[item.status]}"></div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Store'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
        @deleteAction="deleteAction"
    ></ActionPopUp>
  </div>
</template>

<script>
import ActionPopUp from "../ActionPopUp.vue"
import moduleMixin from "@/mixins/moduleMixin"
import tableMixin from "../../mixins/tableMixin"
import moment from "moment"
import customStore from "../../services/customStore"

import DownloadBtn from "../downloadBtn.vue"

export default {
  name: "InventoryPatientProductResume",
  props: {
    patientId: {type: String, default: ''}
  },
  components: {DownloadBtn, ActionPopUp},
  mixins: [moduleMixin, tableMixin],
  data() {
    return {
      statusInventory: {
        'CRITICAL': '#bb1a2f',
        'GOOD': '#4b8333',
        'MINIMAL': '#e69138'
      },
      module: `patient-stock-status-summaries`,
      label: "Busca por producto o tipo",
      statusFilter: [],
      statusList: [{name: 'Crítico', id: 'CRITICAL'}, {name: 'Bueno', id: 'GOOD'}, {name: 'Mínimo', id: 'MINIMAL'}],
    };
  },
  created() {
    this.additionalParams = {
      patientId: this.patientId
    }
    this.headers = [
      {
        text: "Código de producto",
        sortable: true,
        value: "productCode",
      },
      {
        text: "Producto",
        sortable: true,
        value: "product",
      },
      {
        text: "Tipo",
        sortable: true,
        value: "type",
      },
      {
        text: this.getCurrentMonth(),
        sortable: true,
        value: "predictionMonth1"
      },
      {
        text: this.getMonth(0),
        sortable: true,
        value: "predictionMonth2"
      },
      {
        text: this.getMonth(1),
        sortable: true,
        value: "predictionMonth3"
      },
      {
        text: "Cantidad actual",
        sortable: true,
      },
      {
        text: "Status",
        sortable: true,
      },
    ];
  },
  methods: {
    getMonth(month) {
      moment.locale('es')
      const currentMonth = moment()
      const nextMonths = []
      for (let i = 1; i <= 3; i++) {
        nextMonths.push(currentMonth.clone().add(i, 'months').format('MMMM'))
      }
      return `Predicción para ${nextMonths[month]}`
    },
    getCurrentMonth() {
      moment.locale('es')
      const currentMonth = moment().format('MMMM')
      return `Predicción para ${currentMonth}`
    },
    changeFilterStatus() {
      let statusFormatted = "";
      this.statusFilter.forEach((status) => {
        statusFormatted = `${statusFormatted}&status=${status}`
      });
      this.status = statusFormatted;
      this.loadData()
    },
    async refresh() {
      await customStore.getDataCrudByModule('patient-stock-status-summaries/refresh', this.patientId)
      await this.loadData()
    }
  },
};
</script>

<style scoped></style>
