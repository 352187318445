import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import Dashboard from "../views/Dashboard.vue";
import {ACCESS_TOKEN} from "@/utils/constants";
import CRUDRegister from "@/views/CRUDRegister.vue";
import PrintRegister from "@/views/PrintRegister.vue";
import Employees from "@/views/Employees.vue";
import CRUDEmployee from "@/views/CRUDEmployee.vue";
import Panel from "../views/Panel.vue";
import Products from "@/views/Products.vue";
import CRUDProducts from "@/views/CRUDProducts.vue";
import inventoryStore from "../views/inventoryStore.vue";
import CRUDInventoryStore from "../views/CRUDInventoryStore.vue";
import StoreStock from "../views/StoreStock.vue";
import CRUDStoreStock from "../views/CRUDStoreStock.vue";
import Roles from "../views/roles.vue";
import CRUDRole from "../views/CRUDRole.vue";
import Profile from "../views/Profile.vue";
import inventoryPatient from "../views/inventoryPatient.vue";
import CRUDInventoryPatient from "../views/CRUDInventoryPatient.vue";
import PatientsStock from "../views/PatientsStock.vue";
import CRUDPatientsStock from "../views/CRUDPatientsStock.vue";
import CRUDKardex from "../views/CRUDKardex.vue";
import KardexSummary from "../views/KardexSummary.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
            hideAppBar: true,
            hideNavigationDrawer: true,
        },
    },
    {
        path: "/patients",
        name: "patients",
        component: Dashboard,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: Panel,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/employees",
        name: "Employees",
        component: Employees,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudemployee/:crudAction/:id",
        name: "CrudEmployee",
        component: CRUDEmployee,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/products",
        name: "Products",
        component: Products,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/inventory-stores",
        name: "inventoryStores",
        component: inventoryStore,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/inventory-patient",
        name: "inventoryPatient",
        component: inventoryPatient,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudinventorypatients/:crudAction/:id",
        name: "CrudInventoryPatients",
        component: CRUDInventoryPatient,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudproducts/:crudAction/:id",
        name: "CrudProducts",
        component: CRUDProducts,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudinventorystores/:crudAction/:id",
        name: "CrudInventoryStores",
        component: CRUDInventoryStore,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/printRegister/:id",
        name: "PrintRegister",
        component: PrintRegister,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/dashboard/:crudAction/:id",
        name: "CrudRegister",
        component: CRUDRegister,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/store-stocks",
        name: "StoreStock",
        component: StoreStock,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudstorestock/:crudAction/:id",
        name: "CrudStoreStock",
        component: CRUDStoreStock,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/patient-stocks",
        name: "PatientStocks",
        component: PatientsStock,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudpatientstock/:crudAction/:id",
        name: "CrudPatientStock",
        component: CRUDPatientsStock,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/role",
        name: "role",
        component: Roles,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/CrudRole/:crudAction/:id",
        name: "CrudRole",
        component: CRUDRole,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/crudkardex/:crudAction/:idpatient/:id",
        name: "crudkardex",
        component: CRUDKardex,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {
        path: "/kardex",
        name: "kardex",
        component: KardexSummary,
        meta: {
            requiresAuthAdmin: true,
        },
    },
    {path: "*", redirect: "/"},
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});
router.beforeEach((to, from, next) => {
    const isLogged = localStorage.getItem(ACCESS_TOKEN);
    const booleanLogged = JSON.parse(isLogged)
    if (to.matched.some(record => record.meta.requiresAuthAdmin)) {
        if (booleanLogged === null) {
            next('/');
        } else {
            next();
        }
    } else if (to.path === '/' && booleanLogged !== null) {
        next('/dashboard');
    } else {
        next();
    }
})

export default router;
