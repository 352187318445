<template>
  <v-container max-width="1200">
    <Breadcrumbs :items="breadcrumbsItems"/>
    <TopInfoAction
        :show-secondary-btn="isWizard ? false : true"
        :show-right-item="true"
        :title="showTitle"
        :valid-btn="!!(validFormRules && hasAccess('/kardex', 'POST'))"
        secondary-btn-title="Regresar"
        principal-btn-title="Guardar"
        :subtitle="storeName"
        @setDefaultPrimaryAction="save"
        @setDefaultSecondaryAction="cancel"
    />
    <div v-if="loaded">
      <KardexForm :current-element="currentElement"
                  :products="products"
                  :frequencies="frequencies"
                  :subFrequencies="subFrequencies"
                  :dose-types="dosesType"
                  :product-units="productUnits"
                  @validFormInput="validFormInput"
                  @updateProductUnit="getProductUnit"
      ></KardexForm>
      <BottomActions
          :show-secondary-btn="isWizard ? false : true"
          :valid-btn="!!(validFormRules && hasAccess('/kardex', 'POST'))"
          secondary-btn-title="Regresar"
          principal-btn-title="Guardar"
          @setDefaultPrimaryAction="save"
          @setDefaultSecondaryAction="cancel"
      />
    </div>
    <div v-else class="mt-5">
      <v-row>
        <v-col cols="12" lg="6" md="6" xl="6" xs="12">
          <SkeletonLoaded type="card-avatar, article, actions"></SkeletonLoaded>
        </v-col>
      </v-row>
    </div>
    <ActionPopUp
        :dialog="dialog"
        :iteName="'Kardex'"
        :type="type"
        :message="message"
        @cancelAction="dialog = false"
        @closeAction="closeAction"
    ></ActionPopUp>
  </v-container>
</template>

<script>
import Breadcrumbs from "../components/Breadcrumbs.vue";
import TopInfoAction from "../components/TopInfoAction.vue";
import BottomActions from "../components/BottomActions.vue";
import SkeletonLoaded from "@/components/SkeletonLoaded.vue";
import ActionPopUp from "@/components/ActionPopUp.vue";
import CRUDMixin from "@/mixins/CRUDMixin";
import moduleMixin from "../mixins/moduleMixin";
import customStore from "../services/customStore";
import KardexForm from "../components/kardex/kardexForm.vue";


export default {
  name: "CRUDKardex",
  mixins: [CRUDMixin, moduleMixin],
  props: {
    pathBack: {type: String, default: `/crudinventorypatients/edit/`},
  },
  components: {
    KardexForm,
    ActionPopUp,
    SkeletonLoaded,
    TopInfoAction,
    Breadcrumbs,
    BottomActions,
  },
  data() {
    return {
      breadcrumbsItems: [
        {
          text: "Inicio",
          disabled: false,
          path: "/dashboard",
        },
        {
          text: "Inventario por pacientes",
          disabled: false,
          path: "/inventory-patient",
        },
        {
          text: `Editar inventario por paciente`,
          disabled: false,
          path: `/crudinventorypatients/edit/${this.$route.params.idpatient}`,
        },
        {
          text: `
          ${
              (this.$route.params.crudAction === "create"
                  ? "Nuevo"
                  : this.$route.params.crudAction === "edit"
                      ? "Editar"
                      : this.$route.params.crudAction) || ""
          } kardex
          `,
          disabled: true
        }
      ],
      currentElement: {
        productId: null,
        productUnitId: null,
        quantity: null,
        frequency: null,
        daysInterval: null,
        subFrequency: null,
        dailyDoseTime: null,
        hoursInterval: null,
        monthsInterval: null,
        weeksInterval: null,
        doseType: null,
        doseTime: null,
        startDateTimeTime: null,
        endDateTime: null,
        pillCount: null,
        daysOfWeek: null,
        monthsOfYear: null,
        daysOfWeekFreq: null,
        monthsOfYearFreq: null,
        asNeeded: null,
        minimumStock: null,
        indications: null
      },
      module: "kardex",
      titleCreate: "Crear kardex",
      titleEdit: `Editar kardex`,
      frequencies: [],
      subFrequencies: [],
      dailyDoseTimes: [],
      dosesType: [],
      products: [],
      productUnits: [],
      redirectAfterSave: true,
      useRouteParam: true,
      patientDetail: {},
    };
  },
  computed: {
    getPatientName() {
      return `${this.patientDetail?.firstName} ${this.patientDetail?.lastName}`
    },
    storeName() {
      return `${this.patientDetail?.storeName || ''}`
    },
    validFormRules() {
      if (this.currentElement.daysOfWeekFreq && this.currentElement.monthsOfYearFreq && this.currentElement.asNeeded) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.monthsOfYear?.length && this.currentElement.daysOfWeek?.length && this.currentElement?.minimumStock
      }
      if (this.currentElement.daysOfWeekFreq && this.currentElement.asNeeded) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.daysOfWeek?.length && this.currentElement?.minimumStock
      }
      if (this.currentElement.monthsOfYearFreq && this.currentElement.asNeeded) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.monthsOfYear?.length && this.currentElement?.minimumStock
      }

      if (this.currentElement.daysOfWeekFreq && this.currentElement.monthsOfYearFreq) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.monthsOfYear?.length && this.currentElement.daysOfWeek?.length
      }
      if (this.currentElement.asNeeded) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement?.minimumStock
      }
      if (this.currentElement.daysOfWeekFreq) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.daysOfWeek?.length
      }
      if (this.currentElement.monthsOfYearFreq) {
        return this.validForm && this.currentElement.quantity && this.currentElement.doseType && this.currentElement.monthsOfYear?.length
      }
      if (this.currentElement.frequency === 'every_x_days') {
        return this.validForm && this.currentElement.quantity && this.currentElement.frequency && this.currentElement.doseType && this.currentElement.subFrequency
      }
      if (this.currentElement.subFrequency === 'once_per_day') {
        return this.validForm && this.currentElement.quantity && this.currentElement.frequency && this.currentElement.doseType && this.currentElement.subFrequency && this.currentElement.dailyDoseTime
      }
      return this.validForm && this.currentElement.quantity && this.currentElement.doseType
    },
    showTitle() {
      let title = "";
      if (this.$route.params?.crudAction) {
        switch (this.$route.params?.crudAction) {
          case "create":
            title = `${this.titleCreate} ${this.getPatientName || ''}`;
            break;
          case "edit":
            title = `${this.titleEdit} ${this.getPatientName || ''}`;
            break;
          default:
            title = "";
            break;
        }
      }
      return title;
    },
  },

  methods: {
    beforeSend() {
      try {
        this.currentElement.patientId = this.$route.params.idpatient
        this.currentElement.pillCount = parseInt(this.currentElement.pillCount)
        this.currentElement.quantity = parseFloat(this.currentElement.quantity)
        this.currentElement.hoursInterval = parseInt(this.currentElement.hoursInterval)
        this.currentElement.daysInterval = parseInt(this.currentElement.daysInterval)
        this.currentElement.weeksInterval = parseInt(this.currentElement.weeksInterval)
        this.currentElement.monthsInterval = parseInt(this.currentElement.monthsInterval)
        this.currentElement.minimumStock = parseFloat(this.currentElement.minimumStock)
        if (this.currentElement?.productUnit) {
          delete this.currentElement.productUnit
        }
        if (Array.isArray(this.currentElement.monthsOfYear)) {
          this.currentElement.monthsOfYear = this.currentElement.monthsOfYear.join(',')
        }
        if (Array.isArray(this.currentElement.daysOfWeek)) {
          this.currentElement.daysOfWeek = this.currentElement.daysOfWeek.join(',')
        }
        if (this.currentElement.startDateTime && this.currentElement.endDateTime) {
          const [startDay, startMonth, startYear] = this.currentElement.startDateTime.split('/');
          const [endDay, endMonth, endYear] = this.currentElement.endDateTime.split('/');

          this.currentElement.startDateTime = `${startDay}/${startMonth}/${startYear}`;
          this.currentElement.endDateTime = `${endDay}/${endMonth}/${endYear}`;
        }
      } catch (error) {
        console.log(error)
      }

    },
    validFormInput(e) {
      this.validForm = e;
    },
    async loadData() {
      try {
        this.patientDetail = await customStore.getDataCrudByModule(`patients`, this.$route.params.idpatient)
        const productRes = await customStore.getDataByModule('products', '', 0, 10000)
        const frequenciesRes = await customStore.getDataByModule('kardex/frequencies', '', 0, 300)
        const subFrequenciesRes = await customStore.getDataByModule('kardex/sub-frequencies', '', 0, 300)
        const dailyDoseTimesRes = await customStore.getDataByModule('kardex/daily-dose-times', '', 0, 300)
        const doseTypeRes = await customStore.getDataByModule('kardex/dose-types', '', 0, 300)
        this.frequencies = frequenciesRes
        this.subFrequencies = subFrequenciesRes
        this.dailyDoseTimes = dailyDoseTimesRes
        this.dosesType = doseTypeRes
        this.products = productRes.data
      } catch (error) {
        console.log(error)
      }
    },
    async beforeFillData() {
      if (this.currentElement.startDateTime) {
        this.currentElement.startDateTime = `${this.currentElement.startDateTime.split(' ')[0].split('-')[2]}/${this.currentElement.startDateTime.split(' ')[0].split('-')[1]}/${this.currentElement.startDateTime.split(' ')[0].split('-')[0]}`
      }
      if (this.currentElement.endDateTime) {
        this.currentElement.endDateTime = `${this.currentElement.endDateTime.split(' ')[0].split('-')[2]}/${this.currentElement.endDateTime.split(' ')[0].split('-')[1]}/${this.currentElement.endDateTime.split(' ')[0].split('-')[0]}`
      }
      if (this.currentElement.monthsOfYear) {
        const monthsoOYearArray = this.currentElement.monthsOfYear.split(',')
        this.currentElement.monthsOfYear = monthsoOYearArray
      }
      if (this.currentElement.daysOfWeek) {
        const daysOfWeekArray = this.currentElement.daysOfWeek.split(',')
        this.currentElement.daysOfWeek = daysOfWeekArray
      }
      this.currentElement.productId = this.currentElement.productUnit?.productId
      await this.getProductUnit()
    },
    async getProductUnit() {
      const newParams = {
        productId: this.currentElement.productId
      }
      const {data: dataUniProduct} = await customStore.getDataByModuleAndParam(`product-units`, '', 0, 100000, "", "", "Unit", newParams)
      this.productUnits = dataUniProduct
    },
    errorSave() {
    },
    cancel() {
      this.$router.go(-1)
    }
  },
};
</script>

<style scoped></style>
